import React, {useEffect, useState} from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import 'mathlive';
import Graph from "../components/Graph";
import RightTriangle from "../components/RightTriangle";
import AnswerBox from "../components/AnswerBox";
import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';
import parse from 'html-react-parser';
import styles from '../styles/Chat.module.css';
import InteractiveGraph from "../components/InteractiveGraph";


export default function Chat(props) {
  const navigate = useNavigate()
  let [messages, setMessages] = useState([])
  let [answerBoxType, setAnswerBoxType] = useState("")
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [numQuestions, setNumQuestions] = useState(0)
  const [newMessage, setNewMessage] = useState("")
  const {activeAssignment, authorizedFetch} = useOutletContext()
  const [isGraphModalOpen, setIsGraphModalOpen] = useState(false);
  const [graphPoints, setGraphPoints] = useState([]);
  const handleGraphPointsChange = (points) => {
    setGraphPoints(points);
  };
  const [graphData, setGraphData] = useState(null);
  const assignmentId = activeAssignment?.assignment_id
  const [isStreaming, setIsStreaming] = useState(false)


  //messages = messages.slice(0, -1)

  useEffect(() => {
    async function getPrompt() {
      const data = await authorizedFetch(
        `${process.env.REACT_APP_BACKEND_URL}/chat/${assignmentId}/${currentQuestion}`,
      )
      setMessages(data.messages)
      setAnswerBoxType(data.headers)
      setCurrentQuestion(data.current_question)
      setNumQuestions(data.num_questions)
    }
    if (assignmentId !== undefined) {
      getPrompt()
    } else {
      navigate('/student')
    }
  }, [authorizedFetch, navigate, assignmentId, currentQuestion])

  const pastMessagesRef = React.useRef(null);
  useEffect(() => {
    // Scroll to the last message after messages have changed
    if (pastMessagesRef.current) {
      pastMessagesRef.current.scrollTop = pastMessagesRef.current.scrollHeight;
    }
  }, [messages]);

  function prevQuestion() {
    if (currentQuestion <= 0) {
      return;
    }
    setCurrentQuestion(currentQuestion - 1);
    setMessages([]);
  }
  
  function nextQuestion() {
    if (currentQuestion >= numQuestions - 1) {
      return;
    }
    setCurrentQuestion(currentQuestion + 1);
    setMessages([]);
  }
  

  async function sendMessage(user_message, route='note', isExpression=false) {
    
    const cleaned_message = user_message.replaceAll('\\right', '').replaceAll('\\left', '')
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/${route}/${assignmentId}/${currentQuestion}`, {
      headers: {'Authorization': props.token},
      method: "POST",
      body: cleaned_message
    })
    
    const mathed_message = isExpression ? `\\(${user_message}\\)` : user_message
    

    if (response.headers.get('content-type') === 'is_stream') {
      setIsStreaming(true)
      setMessages(prev => [
        ...prev,
        {contents: mathed_message, fromSelf: true, timestamp: new Date()}
      ])

      //this is all just to stream the bot's chat response
      var tmpPromptResponse = '';
      let decoder = new TextDecoderStream();
      
      //if (!response.body) return;
      const reader = response.body.pipeThrough(decoder).getReader();
      let chatCreated = false;
      while (true) {
        var {value, done} = await reader.read();
        if (done) {
          chatCreated = false;
          break;
        } else {
          tmpPromptResponse += value;
  
          if (chatCreated === false) {
            chatCreated = true;
            //this splits the response into multiple messages if it contains the string '//next_chat//', which delimits messages
            if (value.includes('//next_chat//')) {
              // eslint-disable-next-line no-loop-func
              setMessages(prev => [
                ...prev,
                ...tmpPromptResponse.split('//next_chat//').map((chat, i) => {
                  return {contents: chat, fromSelf: false, timestamp: new Date()}
                })
              ])
            } else {
              // eslint-disable-next-line no-loop-func
              setMessages(prev => [
                ...prev,
                {contents: tmpPromptResponse, fromSelf: false, timestamp: new Date()}
              ])
            }
          } else {
            // eslint-disable-next-line no-loop-func
            setMessages(prev => [
              ...prev.slice(0, -1),
              {contents: tmpPromptResponse, fromSelf: false, timestamp: new Date()}
            ])
          }
        }
      }
    } else {
      let data = await response.json();

      setMessages(prev => [
        ...prev,
        {contents: mathed_message, fromSelf: true, timestamp: new Date()},
        ...data['messages']
      ]);
      setAnswerBoxType(data['headers'])
    }

    setIsStreaming(false)

  }
  function submitMessage() {
    sendMessage(newMessage)
    setNewMessage("")
  }
  
  function cleanLatexExpression(expression) {
    console.log(expression)
    return expression
      .replace(/(\d+)\s*\\cdot\s*(?=[a-zA-Z])/g, '$1') // Remove `\cdot` between number and variable (e.g., "3\cdot x" -> "3x")
      .replace(/\\cdot\s*(?=[a-zA-Z])/g, '') // Remove `\cdot` before a variable (e.g., "\cdot x" -> "x")
      .replace(/\b1([a-zA-Z])/g, '$1') // Remove coefficient `1` before variables (e.g., "1x" -> "x")
      .replace(/\+ \-/g, '-') // Convert `+-` to `-`
      .replace(/\- \+/g, '-'); // Convert `-+` to `-`
  }

  function checkForLatex(message) {
    if (message.includes('\\(') || message.includes('$$')) {
      let cleanedMessage = cleanLatexExpression(
        message.replaceAll('$$', '$').replaceAll('\\\\', '\\')
      );
      return <Latex>{cleanedMessage}</Latex>;
    }
    return parse(message)
  }
  function setGraphObject(data){
    setGraphData(data)
    setIsGraphModalOpen(true)
  }

  function renderMessageContents(contents) {
    const graphJSON = contents.includes("GRAPHJSON{") && contents.split("GRAPHJSON")[1].split('\n')[0].replaceAll("'", '"')
    const graphProps = graphJSON && JSON.parse(graphJSON)
    //if (graphProps) console.log(graphProps)
    let table = contents.includes("TABLE[[") && contents.split("TABLE[[")[1].split('\n')[0]
    let triangle_visual = contents.includes("TRIANGLEVISUAL{") && contents.split("TRIANGLEVISUAL")[1].split('\n')[0]
    if (graphProps) {
      const arrayProps = ["xdomain", "ydomain", "axisLabels"]
      for (const arrayProp of arrayProps) {
        if (graphProps[arrayProp]) {
          graphProps[arrayProp] = JSON.parse(graphProps[arrayProp])
        }
      }
      let before = contents.split("GRAPHJSON")[0]
      let after = contents.split("GRAPHJSON")[1].split('\n').slice(1).join("\n")
      return <div className="message">
        {checkForLatex(before)}
        {<Graph {...graphProps}/>}
        {checkForLatex(after)}
      </div>
    } else if (table) {
      table = JSON.parse('[[' + table)
      let before = contents.split("TABLE[[")[0]
      let after = contents.split("TABLE[[")[1].split('\n').slice(1).join("\n")
      return <div className="message">
        {checkForLatex(before)}
        <table><tbody>
          <tr>
            <td className="pointTableCell">X values</td>
            {Array(table[0].length).fill().map((_, i) => <td key={i} className='pointTableCell'>
              {table[0][i]}
            </td>)}
          </tr>
          <tr>
            <td className="pointTableCell">Y values</td>
            {Array(table[1].length).fill().map((_, i) => <td key={i} className='pointTableCell'>
              {table[1][i]}
            </td>)}
          </tr>
        </tbody></table> 
        {checkForLatex(after)}
      </div>
    } else if (triangle_visual) {
      let before = contents.split("TRIANGLEVISUAL{")[0]
      let after = contents.split("TRIANGLEVISUAL{")[1].split('\n').slice(1).join("\n")
      return <div className="message">
        {checkForLatex(before)}
        {<RightTriangle/>}
        {checkForLatex(after)}
      </div>
    }
    return <div className="message">
      {checkForLatex(contents)}
    </div>
  }

  useEffect(() => {
    const scrollAnchor = document.getElementById('scrollAnchor');
    if (scrollAnchor) {
      scrollAnchor.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]); // Run when messages update

  const handleButtonClick = () => {
    alert("Test");
  };

  return (
    <>
      <h2 className={styles.pageTitle}>{activeAssignment?.name}</h2>
  
      <div className={styles.chatContainer}>
        {/* Chat Component */}
        <div className={styles.chat}>
            
          {/* Past Messages */}
          <div className={styles.pastMessages} ref={pastMessagesRef}>
            {messages.map((message, i) => {
              const graphExists =
                message.contents.includes("[[{")
              console.log(message.contents)

              return (
                <div
                  key={i}
                  className={`${styles.messageRow} ${
                    message.fromSelf ? styles.fromSelf : styles.fromServer
                  }`}
                >
                  {graphExists ? (
                    <button onClick={() => setGraphObject(message.contents)}
                    style={{ padding: "10px 15px", cursor: "pointer" }}
                    >Re-Open Interactive Graph</button>
                  ) : message.fromSelf ? (
                    renderMessageContents(message.contents)
                  ) : (
                    renderMessageContents(message.contents)
                  )}
                </div>
              );
            })}
            <div id={styles.scrollAnchor}></div>
          </div>
          {isGraphModalOpen && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              paddingTop: "5%",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "8px",
                width: "400px",
                textAlign: "center",
              }}
            >
              <h3>Interactive Graph</h3>
              <InteractiveGraph
                width={300}
                height={300}
                gridSpacing={30}
                numberOfLines={1}
                onPointsChange={handleGraphPointsChange}
                editable={false}
                points={JSON.parse(graphData)}
              />
              <div style={{ marginTop: "20px" }}>
                <button
                  onClick={() => setIsGraphModalOpen(false)}
                  style={{
                    padding: "10px 15px",
                    backgroundColor: "gray",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
          <div style={{ marginTop: '25px' }}>
            {answerBoxType && answerBoxType !== "false" && (
              <AnswerBox 
                disabled={isStreaming} 
                type={answerBoxType} 
                sendMessage={(message, isExpression) => sendMessage(message, "message", isExpression)}
              />
            )}
          </div>
          {/* Chat with Eden Box */}
          <div className={styles.textBoxWithButton} style={{ marginBottom: '10px', marginTop: '10px'}}>
              <textarea
                  className={styles.newMessage}
                  type="text"
                  placeholder="Chat with Eden"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  disabled={!answerBoxType || answerBoxType === "false"} // Disable if answerBoxType is falsy or "false"
              />
              <img
                  src="/orangesend.png"
                  alt="Submit"
                  className={styles.expressionSubmit}
                  onClick={() => {
                      if (!isStreaming && answerBoxType && answerBoxType !== "false") { // Prevent clicks if disabled
                          submitMessage(newMessage);
                          setNewMessage('');
                      }
                  }}
                  style={{
                      cursor: !answerBoxType || answerBoxType === "false" ? 'not-allowed' : 'pointer', // Change cursor to indicate disabled state
                      opacity: !answerBoxType || answerBoxType === "false" ? 0.5 : 1, // Adjust styling to show disabled state
                  }}
              />
          </div>
          {/* Navigation Section */}
          <div className={styles.navigationContainer}>
            <button
              className={`${styles.navButton} ${styles.prevButton}`}
              onClick={prevQuestion}
              disabled={isStreaming} // Disable the button when streaming
              style={{
                cursor: isStreaming ? 'not-allowed' : 'pointer', // Change cursor when disabled
                opacity: isStreaming ? 0.5 : 1, // Adjust opacity when disabled
              }}
            >
              Previous
            </button>
            <p className={styles.currentQuestion}>
              Current Question: {currentQuestion + 1} of {numQuestions}
            </p>
            <button
              className={`${styles.navButton} ${styles.nextButton}`}
              onClick={nextQuestion}
              disabled={isStreaming} // Disable the button when streaming
              style={{
                cursor: isStreaming ? 'not-allowed' : 'pointer', // Change cursor when disabled
                opacity: isStreaming ? 0.5 : 1, // Adjust opacity when disabled
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <p
      style={{
        color: 'DimGrey',
        textAlign: 'center',
        margin: 0,
        position: 'absolute',
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%',
        zIndex: 1
      }}
    >
      Do not share any personal data with the chatbot. Your chat history can be viewed by your teacher or EdEngage.
    </p>
    </>
  );
}