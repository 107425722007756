import React, { useState, useEffect } from "react";

function InteractiveGraph({ width = 500, height = 500, gridSpacing = 50, numberOfLines = 2, onPointsChange, editable = true, points = Array(numberOfLines).fill([])}) {
  const [hoveredPoint, setHoveredPoint] = useState(null); // Tracks the hovered grid point
  const [selectedPoints, setSelectedPoints] = useState(points); // Tracks selected points for each line
  const [activeLine, setActiveLine] = useState(0); // Tracks the currently active line
  const domain = [-11, 11]; // Set the domain for both axes
  numberOfLines = editable ? numberOfLines : 0

  useEffect(() => {
    if (onPointsChange) {
      onPointsChange(selectedPoints); // Notify parent of points change
    }
  }, [selectedPoints, onPointsChange]);

  const handlePointClick = (x, y) => {
    if (!editable) return;
    setSelectedPoints((prevPoints) =>
      prevPoints.map((linePoints, index) =>
        index === activeLine
          ? linePoints.some((point) => point.x === x && point.y === y)
            ? linePoints.filter((point) => point.x !== x || point.y !== y) // Remove point if it exists
            : linePoints.length >= 2
            ? [{ x, y }] // Reset points if there are already two
            : [...linePoints, { x, y }] // Add new point
          : linePoints
      )
    );
  };

  const calculateInfiniteLine = (p1, p2) => {
    const dx = p2.x - p1.x;
    const dy = p2.y - p1.y;

    if (dx === 0) {
      // Vertical line
      return {
        x1: ((p1.x - domain[0]) / (domain[1] - domain[0])) * width,
        y1: 0,
        x2: ((p1.x - domain[0]) / (domain[1] - domain[0])) * width,
        y2: height,
      };
    }

    const slope = dy / dx;
    const intercept = p1.y - slope * p1.x;

    // Calculate y at graph bounds
    const yAtMinX = slope * domain[0] + intercept;
    const yAtMaxX = slope * domain[1] + intercept;

    // Transform to graph space
    const x1 = 0;
    const y1 = height - ((yAtMinX - domain[0]) / (domain[1] - domain[0])) * height;
    const x2 = width;
    const y2 = height - ((yAtMaxX - domain[0]) / (domain[1] - domain[0])) * height;

    return { x1, y1, x2, y2 };
  };

  const renderGridPoints = () => {
    const buttons = [];

    // Generate grid points as buttons
    for (let i = domain[0]; i <= domain[1]; i++) {
      for (let j = domain[0]; j <= domain[1]; j++) {
        const x = ((i - domain[0]) / (domain[1] - domain[0])) * width;
        const y = height - ((j - domain[0]) / (domain[1] - domain[0])) * height;

        buttons.push(
          <foreignObject
            key={`${i}-${j}`}
            x={x - gridSpacing / 4}
            y={y - gridSpacing / 4}
            width={gridSpacing / 2}
            height={gridSpacing / 2}
            onMouseEnter={() => setHoveredPoint({ x: i, y: j })}
            onMouseLeave={() => setHoveredPoint(null)}
            onClick={() => handlePointClick(i, j)}
          >
            <button
              style={{
                width: "100%",
                height: "100%",
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
              }}
            />
          </foreignObject>
        );
      }
    }

    return buttons;
  };

  const renderGridLines = () => {
    const verticalLines = [];
    const horizontalLines = [];

    // Generate vertical grid lines
    for (let i = domain[0]; i <= domain[1]; i++) {
      const x = ((i - domain[0]) / (domain[1] - domain[0])) * width;
      verticalLines.push(
        <line
          key={`v-${i}`}
          x1={x}
          y1="0"
          x2={x}
          y2={height}
          stroke="#ddd"
          strokeWidth="1"
        />
      );
    }

    // Generate horizontal grid lines
    for (let i = domain[0]; i <= domain[1]; i++) {
      const y = height - ((i - domain[0]) / (domain[1] - domain[0])) * height;
      horizontalLines.push(
        <line
          key={`h-${i}`}
          x1="0"
          y1={y}
          x2={width}
          y2={y}
          stroke="#ddd"
          strokeWidth="1"
        />
      );
    }

    return [...verticalLines, ...horizontalLines];
  };

  const renderLabels = () => {
    const xLabels = [];
    const yLabels = [];

    // Generate x-axis labels
    for (let i = -10; i <= domain[1]; i += 2) {
      if (i !== 0) {
        const x = ((i - domain[0]) / (domain[1] - domain[0])) * width;
        xLabels.push(
          <text
            key={`x-label-${i}`}
            x={x}
            y={height / 2 + 15}
            fontSize="12"
            textAnchor="middle"
          >
            {i}
          </text>
        );
      }
    }

    // Generate y-axis labels
    for (let i = -10; i <= domain[1]; i += 2) {
      if (i !== 0) {
        const y = height - ((i - domain[0]) / (domain[1] - domain[0])) * height;
        yLabels.push(
          <text
            key={`y-label-${i}`}
            x={width / 2 - 15}
            y={y}
            fontSize="12"
            textAnchor="end"
            alignmentBaseline="middle"
          >
            {i}
          </text>
        );
      }
    }

    return [...xLabels, ...yLabels];
  };

  const renderLines = () =>
    selectedPoints.map((linePoints, lineIndex) => {
      if (linePoints.length === 2) {
        const infiniteLine = calculateInfiniteLine(linePoints[0], linePoints[1]);
        return (
          <line
            key={`line-${lineIndex}`}
            x1={infiniteLine.x1}
            y1={infiniteLine.y1}
            x2={infiniteLine.x2}
            y2={infiniteLine.y2}
            stroke={lineIndex === activeLine ? "blue" : "gray"}
            strokeWidth="2"
            strokeDasharray= {lineIndex === activeLine ? "" : ""}
          />
        );
      }
      return null;
    });

  const renderHoverLine = () => {
    if (selectedPoints[activeLine].length === 1 && hoveredPoint) {
      const infiniteLine = calculateInfiniteLine(selectedPoints[activeLine][0], hoveredPoint);
      return (
        <line
          x1={infiniteLine.x1}
          y1={infiniteLine.y1}
          x2={infiniteLine.x2}
          y2={infiniteLine.y2}
          stroke="gray"
          strokeWidth="1"
          strokeDasharray="5,5"
        />
      );
    }
    return null;
  };

  return (
    <div>
      <svg
        width={width}
        height={height}
        style={{ border: "1px solid black" }}
      >
        {/* Grid Lines */}
        {renderGridLines()}

        {/* Axes */}
        <line
          x1="0"
          y1={height / 2}
          x2={width}
          y2={height / 2}
          stroke="black"
          strokeWidth="2"
        />
        <line
          x1={width / 2}
          y1="0"
          x2={width / 2}
          y2={height}
          stroke="black"
          strokeWidth="2"
        />

        {/* Labels */}
        {renderLabels()}

        {/* Lines */}
        {renderLines()}

        {/* Hover Line */}
        {renderHoverLine()}

        {/* Hover Dot */}
        {hoveredPoint && editable && (
          <circle
            cx={((hoveredPoint.x - domain[0]) / (domain[1] - domain[0])) * width}
            cy={
              height -
              ((hoveredPoint.y - domain[0]) / (domain[1] - domain[0])) * height
            }
            r="5"
            fill="gray"
          />
        )}

        {/* Selected Points */}
        {selectedPoints[activeLine].map((point, index) => (
          <circle
            key={index}
            cx={((point.x - domain[0]) / (domain[1] - domain[0])) * width}
            cy={
              height -
              ((point.y - domain[0]) / (domain[1] - domain[0])) * height
            }
            r= {editable ? "5" : "0"}
            fill="red"
            onClick={() => handlePointClick(point.x, point.y)}
          />
        ))}

        {/* Grid Points as Buttons */}
        {renderGridPoints()}
      </svg>

      {/* Line Selector Buttons */}
      <div style={{ marginTop: "10px", textAlign: "center" }}>
        {Array(numberOfLines)
          .fill(null)
          .map((_, index) => (
            <button
              key={index}
              onClick={() => setActiveLine(index)}
              style={{
                margin: "5px",
                padding: "10px 15px",
                backgroundColor: activeLine === index ? "#f98b02" : "white",
                color: activeLine === index ? "white" : "black",
                border: "0px solid black",
                cursor: "pointer",
                borderRadius: "5px"
              }}
            >
              Line {index + 1}
            </button>
          ))}
      </div>
    </div>
  );
}

export default InteractiveGraph;